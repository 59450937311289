import { Fragment } from "react";
import { Section } from "./components/Section/Section";
import { StyledResume } from "./styles";

export const Resume = () => {
  return (
    <StyledResume>
      <div className="header">
        <div>
          <h1 className="name">Yair Velasco</h1>
          <h2 className="position">Senior Frontend (React) Developer </h2>
        </div>
        <div>
          <div>
            <b>Phone:</b> +52 8116555523
          </div>
          <div>
            <b>e-mail:</b> yair_vnp@hotmail.com
          </div>
        </div>
      </div>
      <hr></hr>
      <Section title="PROFESIONAL SUMMARY">
        <ul>
          <p>{professionalSummary}</p>
        </ul>
      </Section>
      {/* <Section title="WORK EXPERIENCE SUMMARY">
        <ul>
          {workExperienceSummary.map((experience, index) => (
            <li key={index}>
              <div className="experience">
                <div className="info">{`${experience.company} - ${experience.position}`}</div>
                <span className="years">{experience.years}</span>
              </div>
            </li>
          ))}
        </ul>
      </Section> */}
      <Section title="WORK EXPERIENCE">
        <Fragment>
          {workExperience.map((experience, index) => (
            <div key={index} className="work-experience">
              <div className="company-name">{experience.companyName}</div>
              <div className="position">
                {experience.position}
                <span>{experience.dates}</span>
              </div>
              <div>{experience.description}</div>
              <ul>
                {experience.keyRoles.map((keyRole, index) => (
                  <li key={index}>{keyRole}</li>
                ))}
              </ul>
              <div className="technologies">
                <span>Technologies: </span>
                {experience.techList}
              </div>
            </div>
          ))}
        </Fragment>
      </Section>
      <Section title="TECHNICAL SKILLS">
        <Fragment>
          {technicalSkills.map((skill, index) => (
            <div key={index} className="tech-skill">
              <div className="title">{skill.title}</div>
              <div className="list">{skill.list}</div>
            </div>
          ))}
        </Fragment>
      </Section>
      <Section title="EDUCATION">
        <div className="education">
          <span className="program-name">
            B.S. in Animation and Digital Art,
          </span>{" "}
          ITESM University, Mexico 2015
        </div>
      </Section>
    </StyledResume>
  );
};

const workExperience = [
  {
    companyName: "Regrow",
    position: "Senior Frontend Engineer",
    dates: "Sep 2022 - Mar 2025",
    description:
      "Regrow is the role model plattform for agricultural resiliency. One of my key focus has been the development of our product Sustainability Insights, contributing in multiple features such as interactive data visualization dashboards and also supporting components.",
    keyRoles: [
      "Created the Sustainability Reporting dashboard for clients to access and monitor their supply sheds at large scale.",
      "Created the Supply Shed comparison feature to enable customers to see historical differences at glance.",
      "Planned and refactored migrations for the usage of redux to SWR for API data",
      "Increased significantly unit test coverage for supporting calculation functions",
      "Contributed for E2E tests using playwright",
      "Contributed to Leaf Design System (Regrow's design system) components using MUI",
    ],
    techList:
      "React, TypeScript, styled-components, Redux, SWR, MUI, Playwright",
  },
  {
    companyName: "Flote",
    position: "Lead Frontend Developer (React)",
    dates: "Aug 2021 - Oct 2022",
    description:
      "Flote is a Social Network Platform censorship resistant. I helped building the interface for it, introducing new UI components, implementing auth system, app state managing tools and leading the frontend team for coding practicesand standards.",
    keyRoles: [
      "Introduced new features from scratch.",
      "Helped with best practices for code and refactor for optimization.",
    ],
    techList:
      "React, TypeScript, styled components, MobX, GraphQL/Apollo Client, Quill",
  },
  {
    companyName: "AgileThought",
    position: "Senior React Developer",
    dates: "Aug 2020 - Aug 2021",
    description:
      "Worked in a development team to refactor and complete an application for a law firm called Kirkland & Ellis which is goingto be used to manage fundings in a better and more elegant way.",
    keyRoles: [
      "Crafted clean features from planning to release",
      "Refactored old code to match updated technologhy involved in the project and better practices.",
      "Participated in technical sessions to help decide the best solution for some data related issues.",
    ],
    techList: "React, TypeScript, MobX, Sass modules",
  },
  {
    companyName: "IBM",
    position: "UX/UI Lead Developer",
    dates: "Oct 2017 - Aug 2020",
    description:
      "Worked as a UX/UI Lead developer, in charge of a team of 5 people, in the creation of a Contracting solution whichcurrently holds near 1,000 procurement professional users across IBM company. Within the tool, they can manage theircontracts and detect risks related to several areas and link them with purchase orders.",
    keyRoles: [
      "Designed and created a UX flow and UI for the base and complex features in the application.",
      "Ran a Design Thinking workshop in Raleigh, NC, USA, on how to get closer to the client needs and develop a good roadmap for us to follow. Over 25 people attended the workshop.",
      "Worked on the follow-up as lead for the development outcomes and match them with the purpose of UX and UI during the entire project.",
      "Developed several parts of the application using jQuery as base library for the front-end and Node.js in the backend.",
      "Lead a React migration that migrated everything that was created into React to maintain the code and accelerate the development.",
    ],
    techList: "React, UX, UI, jQuery, Node.js",
  },
];



const professionalSummary =
  `Results driven Performance Engineer with over 7 years of experience building complex software applications with React. Well-versed in the usage of Scrum, Agile and Design Thinking methodologies with vast experience in collaborating with business-enabling areas to manage the system and how the team develops. Very interested in persuing a career as architect I keep growing my tech stack and practices to achieve excellence.`;

/* const workExperienceSummary = [
  {
    company: "Flote",
    position: "Lead Frontend Developer (React)",
    years: "2021 - Present",
  },
  {
    company: "AgileThought",
    position: "Senior React Developer",
    years: "2020 - 2021",
  },
  { company: "IBM", position: "UX/UI Lead Developer", years: "2018 - 2020" },
  {
    company: "IBM",
    position: "Junior Application Developer/Iteration Manager",
    years: "2017 - 2017",
  },
  {
    company: "IBM",
    position: "Junior Applica on Developer/UX Designer",
    years: "2017 - 2017",
  },
]; */

const technicalSkills = [
  {
    title: "Software and Tools:",
    list: "React, MobX, Redux, styled-components, Apollo GraphQL, Node.js, Express, Cloudant(MongoDB), NestJs, HTML, CSS, SCSS, DevOps, JIRA, Git",
  },
  {
    title: "Languages:",
    list: "JavaScript, TypeScript, SQL, GQL",
  },
];
